<template>
  <div>
    <v-btn
      v-if="itemID"
      small
      rounded
      dark
      depressed
      color="cyan"
      class="mx-1"
      @click.stop="showDialog()"
    >
      Edit
    </v-btn>
    <v-tooltip bottom v-else>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          icon
          @click.stop="showDialog"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-account-plus</v-icon>
        </v-btn>
      </template>
      <span>Create User</span>
    </v-tooltip>
    <v-dialog v-model="dialog" persistent max-width="400" scrollable>
      <v-card>
        <v-card-title class="title">{{ title }}</v-card-title>
        <v-card-text>
          <create-user-form
            :itemId="itemID"
            ref="createUserForm"
            :form="form"
            :key="componentKey"
          ></create-user-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog()" color="error"> Cancel </v-btn>
          <v-btn
            class="bg-secondary text-color-white"
            @click="submit()"
            :loading="loading"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { GLOBAL_EVENT_BUS } from "@/services/event_buses/globalEventBus";
import { RepositoryFactory } from "@/services/repositories/repositoryFactory";
const userRepository = RepositoryFactory.get("user");

export default {
  components: {
    CreateUserForm: () => import("../forms/CreateUserForm")
  },
  props: {
    itemID: {
      type: Number,
      default: null
    },
    eventName: {
      type: String,
      required: true
    },
    form: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data: () => ({
    dialog: false,
    loading: false,
    componentKey: 0
  }),
  computed: {
    title() {
      return this.itemID ? "Edit Account" : "Create Account";
    }
  },
  methods: {
    showDialog() {
      this.dialog = true;

      if (!this.itemID) {
        this.form = {};
      }
    },
    closeDialog() {
      this.componentKey++;
      this.dialog = false;
    },
    async create(form) {
      await userRepository
        .create(form)
        .then(() => {
          GLOBAL_EVENT_BUS.$emit(this.eventName);

          this.MIXINS_SHOW_SNACKBAR("success", "Successfully Created.");
        })
        .catch(error => {
          console.log({ error });
          let objectError = error.response.data.errors;

          for (const [key] of Object.entries(objectError)) {
            let errorMessage = objectError[key][0];

            this.MIXINS_SHOW_SNACKBAR("error", errorMessage);

            break;
          }
        })
        .finally(() => {
          this.loading = false;
          this.closeDialog();
        });
    },
    async update(form) {
      await userRepository
        .update(this.itemID, form)
        .then(() => {
          GLOBAL_EVENT_BUS.$emit(this.eventName);

          this.MIXINS_SHOW_SNACKBAR("success", "Successfully Updated.");
        })
        .catch(error => {
          console.log({ error });
          let objectError = error.response.data.errors;

          for (const [key] of Object.entries(objectError)) {
            let errorMessage = objectError[key][0];

            this.MIXINS_SHOW_SNACKBAR("error", errorMessage);

            break;
          }
        })
        .finally(() => {
          this.loading = false;
          this.closeDialog();
        });
    },
    submit() {
      let createUserForm = this.$refs.createUserForm;
      let valid = createUserForm.validate();

      if (valid) {
        let form = createUserForm.form;
        /**
         * For now delete profile picture in payload because uploading of profile picture is not applicable
         */
        delete form.profile_picture;

        if (form.password !== form.password_confirmation) {
          this.MIXINS_SHOW_SNACKBAR("error", "Password does not match.");

          return;
        }

        this.loading = true;

        if (this.itemID) {
          this.update(form);

          return;
        }

        this.create(form);

        return;
      }
    }
  }
};
</script>
